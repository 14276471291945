<template>
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <form class="form-horizontal" role="form" @submit.prevent="submitForm">
          <b-form-group id="type" label-cols-sm="2" label-cols-lg="2" :label="$t('form.tax.fields.titleField')"
            label-for="type" :invalid-feedback="titleValidationMessage" :state="!isTitleValid ? false : null">
            <b-form-input id="type" v-model="item.title" @input="validateTitleField"
              :state="!isTitleValid ? false : null"></b-form-input>
          </b-form-group>
          <b-form-group id="description" label-cols-sm="2" label-cols-lg="2"
            :label="$t('form.tax.fields.descriptionField')" label-for="description"
            :invalid-feedback="descriptionValidationMessage" :state="!isDescriptionValid ? false : null">
            <b-form-input id="description" v-model="item.description" @input="validateDescriptionField"
              :state="!isDescriptionValid ? false : null"></b-form-input>
          </b-form-group>

          <b-form-group id="percent" label-cols-sm="2" label-cols-lg="2" :label="$t('form.tax.fields.percentageField')"
            label-for="percent" :invalid-feedback="percentValidationMessage" :state="!isPercentValid ? false : null">
            <b-form-input id="percent" v-model="item.percent" @input="validatePercentField"
              :state="!isPercentValid ? false : null"  :formatter="formatNumberDouble"></b-form-input>
          </b-form-group>
          <b-button variant="primary" type="submit" :disabled="formValidationButton">{{ $t('buttons.save') }}</b-button>
        </form>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      formValidationButton: true,
      isTitleValid: true,
      isDescriptionValid: true,
      isPercentValid: true,
      titleValidationMessage: null,
      descriptionValidationMessage: null,
      percentValidationMessage: null
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        title: {
          type: String,
          default: '',
        },
        description: {
          type: String,
          default: '',
        },
        percent: {
          type: Number,
        },
        is_active: {
          type: Boolean,
          default: true,
        },
      }),
    },
    type: {
      type: String,
      default: ''
    }
  },
  watch: {
    'item'() {
      const self = this
      self.validateTitleField();
      self.validateDescriptionField();
      self.validatePercentField();
      self.validateForm()
    },
  },
  computed: {
    isFormValid() {
      return this.isTitleValid && this.isDescriptionValid && this.isPercentValid;
    },
  },
  methods: {
    formatNumberDouble(value){
      return value.replace(/,/g, '.')
    },
    isStrictDouble(str) {
      const doubleRegex = /^[+-]?[0-9]*\.?[0-9]+([eE][+-]?[0-9]+)?$/;
      const integerRegex = /^[-+]?\d+$/;

      if (integerRegex.test(str)){
        return false
      }
      if (doubleRegex.test(str)){
        return false
      }
      return true
    },
    validateTitleField() {
      this.isTitleValid = this.item.title !== null && this.item.title.length > 0;
      this.titleValidationMessage = this.isTitleValid ? '' : this.$t('form.tax.validation.titleMandatory');
      this.validateForm()
    },
    validateDescriptionField() {
      this.isDescriptionValid = this.item.description !== null && this.item.description.length > 0;
      this.descriptionValidationMessage = this.isDescriptionValid ? '' : this.$t('form.tax.validation.descriptionMandatory');
      this.validateForm()
    },
    validatePercentField() {
      this.isPercentValid = this.item.percent !== null && this.item.percent.length > 0;
      this.percentValidationMessage = this.isPercentValid ? '' : this.$t('form.tax.validation.percentageMandatory');


      if (this.isStrictDouble(this.item.percent)) {
          this.isPercentValid = false
          this.percentValidationMessage = this.isPercentValid ? '' : this.$t('form.tax.validation.percentageFormatInvalid');
      }else{
        this.isPercentValid = true
        this.percentValidationMessage = null
      }

      this.validateForm()
    },
    validateForm() {
      var isTitleValid = this.item.title !== null && this.item.title.length > 0;
      var isDescriptionValid = this.item.description !== null && this.item.description.length > 0;
      var isPercentValid = true
      if (this.isStrictDouble(this.item.percent)) {
          isPercentValid = false
      }
      console.log(isPercentValid)
      this.formValidationButton = !(isTitleValid && isDescriptionValid && isPercentValid);
    },
    submitForm() {
      if (this.isFormValid) {
        const json = JSON.stringify({
          title: this.item.title,
          description: this.item.description,
          percent: this.item.percent,
          is_active: true,
        });
        this.$emit('data', json);
      }
    },
  },
  mounted() {
    if (this.type == "CREATE") {
      this.isTitleValid = true
      this.isDescriptionValid = true
      this.isPercentValid = true
      this.titleValidationMessage = ""
      this.descriptionValidationMessage = ""
      this.percentValidationMessage = ""
      this.formValidationButton = true
    }
  }
};
</script>