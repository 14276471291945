<script>
import PageHeader from "@/components/page-header";
import FormTax from "@/views/pages/tax/form.vue"
import { ApiClientSelectedPlatform } from "@/common/http-common"
import { BredcrumpManagerEncode } from "@/common/bredcrumb-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"

export default {
  components: {
    PageHeader,  FormTax
  },
  data() {
    return {
      title: this.$t('taxes.update'),
      item:{
        id:"",
        title:"",
        description:"",
        percent:null
      }
    };
  },
  methods: {
    getItem(platformId,itemId) {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      ApiClientSelectedPlatform(platformId).get("/v1/admin/domains/item/tax/item?itemId="+itemId).then(function (response) {
        console.log(response)
        if (response.data){
          if (response.data.item){
            self.item = response.data.item
          }
        }
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    },
    updateItem(data) {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      var rs = JSON.parse(atob(this.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).put("/v1/admin/domains/item/tax/item?itemId="+rs.itemId, data).then(function () {
        if (self.$route.query.data) {
          var rs = JSON.parse(atob(self.$route.query.data))
          if (rs.items) {
            self.$router.replace({ path: rs.items[rs.items.length - 2].path, query: { data: rs.items[rs.items.length - 2].data } }).catch(() => { });
          }
        }
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    }
  },
  mounted() {
    document.getElementById("preloader").style.display = "none";
    document.getElementById("status").style.display = "none";
    var rs = JSON.parse(atob(this.$route.query.data))
    this.getItem(rs.platformId,rs.itemId)
    BredcrumpManagerEncode(this, { path: this.$route.path, title: 'taxes.update'})
  }
};
</script>
<template>
    <div class="row">
        <div class="col-xl-12">
        <PageHeader :title="title" />
      </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ this.$t('taxes.update') }}</h4>
              <p class="card-title-desc">
              </p>
              <div class="row">
                <FormTax :item="item" :type="'UPDATE'" @data="updateItem"></FormTax>
                </div>
            </div>
          </div>
        </div>
      </div>
</template>